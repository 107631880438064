const dark = {
  primary: "#17191aff",
  secondary: "#005B41",
  tertiary: "#008170",
  quaternary: "#23ce6bff",
  quinary: "#a64253ff",
  axesList: "#464a4f",
  red: "#ff4136ff",
  green: "rgb(46,164,79)",
  textBackground: "#232D3F",
  contrastText: "#fff",
  text: "white",
};

const light = {
  primary: "#fbf5f3ff",
  secondary: "#23ce6bff",
  tertiary: "#fbf5f3ff",
  quaternary: "#23ce6bff",
  quinary: "#a64253ff",
  axesList: "#464a4f",
  red: "#ff4136ff",
  green: "rgb(46,164,79)",
  contrastText: "black",
  text: "white",
  transparentWhite: "rgba(255, 255, 255, 0.3)",
};

export default dark;
