import colorscheme from "./colorscheme";
const ArrowColors = [
  "#fe5f55ff",
  "#2b50aaff",
  "#eec643ff",
  "#d7fcd4ff",
  "#DAD2D8",
];

export { ArrowColors };
